<template>
  <div class="md-app-container login-page">
    <md-card>
      <md-ripple>
        <md-card-header>
          <div class="md-subhead">{{ $t('resetPass') }}</div>
        </md-card-header>

        <md-card-content>
          <md-field md-inline>
            <label>Name</label>
            <md-input v-model="name" aria-required="true" disabled="disabled"></md-input>
          </md-field>
          <md-field md-inline>
            <label>{{ $t('password') }}</label>
            <md-input v-model="password" type="password" aria-required="true"></md-input>
          </md-field>
          <md-field md-inline>
            <label>{{ $t('resetPassConfirm') }}</label>
            <md-input v-model="password_confirmation" type="password" aria-required="true"></md-input>
          </md-field>

          <md-card-actions>
            <md-button class="md-raised" v-on:click="this.resetPassword">{{ $t('reset') }}</md-button>
          </md-card-actions>
        </md-card-content>

        <Messages :messages="messages"></Messages>
        <Errors :errors="errors"></Errors>
      </md-ripple>
    </md-card>
  </div>
</template>

<script>
import Errors from "@/components/Errors";
import Messages from "@/components/Messages";
import {errors} from "@/mixins/errors"
import {messages} from "@/mixins/messages"
import {printArray} from "@/mixins/printArray"

export default {
  name: "ResetPassword",

  data() {
    return {
      token: null,
      name: null,
      password: null,
      password_confirmation: null,
      has_error: false,
      isDisabled: false,
    }
  },
  components: {
    Errors,
    Messages
  },
  mixins: [
    printArray,
    errors,
    messages,
  ],
    mounted() {
      this.getUser( this.$route.query.token)
    },
    methods: {
    resetPassword() {

      this.clearErrors()
      this.clearMessages()

      if(!this.isDisabled) {
        this.isDisabled = true

        this.$axios.post(process.env.VUE_APP_API_URL + "/auth/password/reset", {
          token: this.$route.query.token,
          name: this.name,
          password: this.password,
          password_confirmation: this.password_confirmation
        },
            {
              /*todo*/
              headers: {
                "Access-Control-Allow-Origin": "*",
              }
            }
      )
            .then(response => {
              this.isDisabled = false

              if (response.data.errors.length > 0) {
                this.setErrors(response.data.errors);

                this.isDisabled = false
              } else {
                this.addMessage(response.data.message);

                setTimeout( () => this.$router.push({ path: '/login'}), 2000);
              }
            })
            .catch(err => {
              this.isDisabled = false

              this.setErrors(err.response.data.errors);
            })
      }
    },

        getUser() {
        const token = this.$route.query.token

            this.clearErrors()
            this.clearMessages()

            if(!this.isDisabled) {
                this.isDisabled = true

                this.$axios.get(process.env.VUE_APP_API_URL + '/auth/password/reset/'+ token +'/user')
                    .then(response => {
                        this.isDisabled = false
                        this.name = response.data
                    })
                    .catch(err => {

                        this.setErrors(err.response.data.errors);
                    })
            }
        }
  }
}
</script>

<style scoped lang="scss">

.md-card{
    padding: 30px 60px;
}

.login-page{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #054F63!important;
    flex-direction: column;
}
.logo{
    margin: 20px 0 30px 15px;
}
.md-subhead{
    font-weight: 900;
    font-size: 36px;
    line-height: 52px;
    color: #000000;
    opacity: unset;
}
.header_text{
    width: 315px;
}
.email{
    background: #FFFFFF;
    border: 1px solid #CDD0D9;
    box-sizing: border-box;
    border-radius: 6px;
}
.email >label {
    margin-top: -7px;
    margin-left: 16px;
}
.email_img {
    padding-right: 9px;
}
.button_part{
    justify-content: end;
    margin-left: 7px;
}
.md-raised{
    background: #EC8B31!important;
    border-radius: 6px;
    color: white!important;
    text-transform: capitalize;
    width: 160px;
    height: 36px;
}
.password_section{
    height: 450px;
    width: 450px;
}
.footer_section{
    margin-top: 85px;
    justify-content: center;
    display: flex;
}
.footer_section p{
    color: white;
    width: 61%;
}
.md-button-content{
    color: white!important;
}
</style>